<template>
  <div class="mt-5">
    <v-card flat>
      <v-toolbar dense elevation="0">
        <!-- <v-select
          v-model="shiftName"
          label="Select Shift"
          dense
          outlined
          :items="ShiftItems"
          item-text="workshift_name"
          item-value="workshift_id"
          @change="get_leave_report_pagination(shiftName)"
          class="mt-5"
          style="max-width: 220px"
        ></v-select> -->
        <v-dialog
          ref="dialog"
          v-model="modal"
          color="#f4813f"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              dense
              outlined
              class="mt-5 ml-2"
              persistent-hint
              :disabled="isLoading == true"
              label="Filter by Date"
              color="#f4813f"
              style="max-width: 180px"
              readonly
              append-icon="mdi-calendar"
              @click:append="date ? getcal() : getcal()"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :min="minmonth"
            v-model="date"
            color="#f4813f"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="#f4813f" @click="modal = false"> Cancel </v-btn>
            <v-btn
              text
              color="#f4813f"
              v-on:click="get_leave_report_pagination()"
              @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-text-field
          label="Search"
          class="mt-5 ml-2"
          v-model="search"
          dense
          style="max-width: 220px"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="leavereport"
          :items="leaveitems"
          dense
          :search="search"
          :height="height"
          :loading="isLoading"
          class="overflow-y-auto"
          :items-per-page="50"
          :fixed-header="fixed"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Leave / swipe data available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.leave_type`]="{ item }">
            <span v-if="item.leave_type == 'HL'">Half Day</span>
            <span v-else>Full Day</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { leaveReports } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";

export default {
  components: {
    Overlay,
  },
  data: (vm) => ({
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    height: 0,
    isLoading: false,
    fixed: true,
    overlay: false,
    search: "",
    ShiftItems: [],
    shiftName: "ALL",
    leaveitems: [],
    leavereport: [
      { text: "Member Id", value: "employee_id" },
      { text: "Member Name", value: "user_name" },
      { text: "Leave Type", value: "leave_type" },
      { text: "Contact Number", value: "user_full_contact_number" },
    ],
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.height = window.innerHeight - 210;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    // this.overlay = true;
    this.get_leave_report();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    get_leave_report_pagination() {
      this.leaveitems = [];
      this.get_leave_report();
    },
    async get_leave_report() {
      try {
        let result = await API.graphql(
          graphqlOperation(leaveReports, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_time_stamp: new Date(this.date).getTime() / 1000,
              //  1691652436,
              limit: 50,
              nextToken: null,
            },

            // new Date(this.date).getTime() / 1000
          })
        );
        this.leaveitems = result.data.leaveReports.data;
        // console.log(this.leaveitems);
      } catch (err) {
        console.log(err);
      }
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>